<template>
  <div>
    <occupy-approve />
    <photo-approve />
    <div class="bottom">
      <img
        :src="require('@/assets/images/echarts/outDoor/bottom.png')"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import occupyApprove from '@/views/echarts/teamPhoto/components/occupyApprove'
  import photoApprove from '@/views/echarts/teamPhoto/components/photoApprove'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'Index',
    components: { occupyApprove, photoApprove },
    data() {
      return {}
    },
    created() {
      const userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'teamPhotoEcharts',
        '城管报表-中队处理情况分析',
        'zzdcg.yy.gov.cn/index'
      )
    },
  }
</script>

<style scoped></style>
