import request from '@/util/request'

export function getOccupyApprove(params) {
  return request({
    url: '/TeamPhoto.asmx/GetOccupyApprove',
    method: 'GET',
    params,
  })
}

export function getPhotoApprove(params) {
  return request({
    url: '/TeamPhoto.asmx/GetPhotoApprove',
    method: 'GET',
    params,
  })
}
